@import '../scss/icons.scss';
@import '../scss/config/default/bootstrap.scss';
@import '../scss/config/default/app.scss';
@import '../scss/config/default/custom.scss';
@import './shepherd.css';
@import './mermaid.min.css';

//RTL
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";

//Rigthsidebar offcanvas
.offcanvas-header-dark {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

body {
  * {
    outline: none;
  }
}

.noUi-horizontal .noUi-handle {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  right: -10px !important;
  top: -7px !important;
}

.noUi-connect,
.noUi-horizontal {
  height: 2px !important;
}

.noUi-handle {
  background: #0ab39c !important;
  border: 2px solid var(--vz-card-bg) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.noUi-target {
  background: #fafafa !important;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
  background-color: var(--vz-light) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
  height: 4px !important;
}

.dropzone .dz-message {
  text-align: center;
}

// //Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 2px;
  font-size: 12px;
  margin-right: 2px;
}

.fc-mobile{
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 0px !important;
  font-size: 9px !important;
  margin-right: 2px;
}
.fc-toolbar{
  margin-left: 25px !important;
  margin-right: 25px !important;
}
}






.fc-event {
  border: none !important;
}

// @include media-breakpoint-down(sm) {
//   .fc-toolbar {
//     display: block !important;
//     text-align: center;

//     .fc-left,
//     .fc-right,
//     .fc-center {
//       float: none;
//       display: block;
//       clear: both;
//       margin: 10px 0;
//     }
//   }

//   .fc {
//     .fc-toolbar {
//       >* {
//         >* {
//           float: none;
//         }
//       }
//     }
//   }

//   // .fc-today-button {
//   //   display: none;
//   // }
// }

.flatpickr-calendar.open {
  z-index: 1090 !important;
}

.upcoming-scheduled .flatpickr-input[readonly] {
  display: none;
}

.ql-container.ql-snow {
  height: 250px;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #405189 !important;
}

// .Toastify__toast {
//   min-height: 43px !important;
//   max-height: 43px !important;
// }

@each $color,
$value in $theme-colors {
  .simplebar-track-#{$color} {
    .simplebar-scrollbar:before {
      background: $value !important;
    }
  }
}

// chat
.chat-conversation .chat-list .message-box-drop {
  .nav-btn {
    font-size: 18px;
    padding: 4px;
    color: $gray-600;
  }
}

// authentication

#tsparticles {
  canvas {
    position: absolute !important;
  }
}

.alert-msg-show {
  right: 24px;
  top: 40px;
  z-index: 1005;
}

// Emoji Picker

.pickerEmoji {
  position: absolute;
  bottom: -100px;
  left: 10%;
  transform: translateX(-50%);
}

.Toastify {
  .Toastify__toast {
    min-height: 43px;
  }

  .Toastify__close-button {
    top: 6px;
  }

  .Toastify__close-button--light {
    color: $light;
  }
}

.airgp-logo {
  color: white;
  font-size: 27px;
  font-weight: 600;
}

.airgp-logo-nav {
  color: black;
  font-size: 27px;
  font-weight: 600;
}

.logo-color {
  color: orange;
}

// .google-autocomplete-class {
//   position: absolute;
//   z-index: 10;
// }
.add-session-modal {
  margin-right: 7px;
}

.cursor {
  cursor: pointer;
}

.min-height1 {
  min-height: 15vh;
}

.margin-top {
  margin-top: 30px;
}

.margin-right {
  margin-right: 20px;
}

.rounded-circle {
  height: 30px;
  width: 30px;
}

.avatar-xxs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  // background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

// avatar group
.avatar-group {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;

  .avatar-group-item {
    margin-left: -12px;
    border: 2px solid $card-bg;
    border-radius: 50%;
    transition: all 0.2s;

    &:hover {
      position: relative;
      transform: translateY(-2px);
      z-index: 1;
    }
  }
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.red-color {
  color: #f06548;
}

.green-color {
  color: #0ab39c;
}

.fc-event {
  cursor: pointer;
}

#signature-pad {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 10px;
}

.signature-canvas {
  cursor: crosshair;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.5) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.sig-btns {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  margin: 0 0 0 25px;
}

.profile-color-A {
  background-color: red;
}

.profile-color-B {
  background-color: rgb(198, 133, 133);
}

.profile-color-C {
  background-color: rgb(150, 211, 45);
}

.profile-color-D {
  background-color: rgb(219, 187, 187);
}

.profile-color-E {
  background-color: rgb(206, 233, 30);
}

.profile-color-F {
  background-color: rgb(81, 24, 24);
}

.profile-color-G {
  background-color: rgb(69, 134, 232);
}

.profile-color-H {
  background-color: rgb(189, 73, 210);
}

.profile-color-I {
  background-color: rgb(152, 15, 15);
}

.profile-color-J {
  background-color: rgb(102, 96, 96);
}

.profile-color-K {
  background-color: rgb(5, 84, 104);
}

.profile-color-L {
  background-color: red;
}

.profile-color-M {
  background-color: rgb(159, 79, 79);
}

.profile-color-N {
  background-color: red;
}

.profile-color-O {
  background-color: red;
}

.profile-color-P {
  background-color: rgb(220, 98, 98);
}

.profile-color-Q {
  background-color: red;
}

.profile-color-R {
  background-color: rgb(245, 249, 4);
}

.profile-color-S {
  background-color: red;
}

.profile-color-T {
  background-color: rgb(29, 0, 0);
}

.profile-color-U {
  background-color: rgb(221, 126, 126);
}

.profile-color-V {
  background-color: rgb(159, 24, 85);
}

.profile-color-W {
  background-color: red;
}

.profile-color-X {
  background-color: rgb(222, 103, 103);
}

.profile-color-Y {
  background-color: rgb(229, 97, 97);
}

.profile-color-Z {
  background-color: rgb(61, 36, 36);
}

// Spinner 2 scss //////////////////////////////////////////////

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


  .no-internet {
    position: fixed;
    top: 107px;
    z-index: 1002;
    width: 100%;
    height: 46px;
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: height 0.5s;

    @media (max-width: 1024px) {
      position: fixed;
      top: 62px;
      z-index: 1002;
      width: 100%;
      height: 26px;
      color: white;
      justify-content: center;
      display: flex;
      align-items: center;
      transition: height 0.5s;
    }
  }

  .internet {
    position: fixed;
    top: 107px;
    z-index: 1002;
    width: 100%;
    height: 0px;
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    visibility: hidden;
    transition: height 0.5s , visibility 0.2s;

    @media (max-width: 1024px) {
      position: fixed;
      top: 62px;
      z-index: 1002;
      width: 100%;
      height: 0px;
      color: white;
      justify-content: center;
      display: flex;
      align-items: center;
      visibility: hidden;
      transition: height 0.5s , visibility 0.2s;
    }
  }

